import { AcceptedLocalsType } from 'cosmos-components/dist/i18n';
import { BrandNames } from 'src/enums';
import { DotItem } from '../CarousalDots';

export interface SciCarouselDotsState {
    currentPage: SciPagesEnum;
    items: DotItem[];
    flowCompleted: boolean;
}

export interface SciPageState {
    brandName?: BrandNames;
    language?: AcceptedLocalsType;
    carouselDotsState?: SciCarouselDotsState;
}

export enum SciPagesEnum {
    RetrieveSelfCheckInPage,
    UpdateCustomerPage,
    AddDriverPage,
    AddAdditionalDriversPage,
    VehicleProtections,
    Extras,
    Videos,
    Payment,
    RegistrationComplete,
}
