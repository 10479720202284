import styled from 'styled-components';
import { PrimaryButton, FormField, SecondaryButton } from 'cosmos-components';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export const StyledFormField = styled(FormField)`
    margin-top: 1.5rem;
    margin-bottom: 0;
    flex: 1 1 0;
    label {
        margin-bottom: 0.5rem;
    }
`;

export const ActionWrapper = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        flex-direction: column;
    }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin: 1.6rem 0;
    width: 49%;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 1;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
    position: relative;
    color: ${({ theme: { colors } }) => colors.secondaryOrange};
    border: 1px solid ${({ theme: { colors } }) => colors.secondaryOrange};
    margin: 1.6rem 0;
    width: 49%;

    &:hover,
    &:focus,
    &:active {
        color: ${({ theme: { colors } }) => colors.secondaryOrange};
        border-color: ${({ theme: { colors } }) => colors.secondaryOrange};
        background-color: transparent;
    }

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 2;
        margin: 0;
    }
`;

export const CustomerInfoForm = styled.form`
    margin: auto;
`;

export const Row = styled.div`
    display: flex;
`;

export const LoyaltyMembershipRow = styled(Row)`
    margin-bottom: 2rem;
`;

export const PassengersHeader = styled.h3`
    margin-top: 1.8rem;
    margin-bottom: 0;
    padding: 0.8rem 0rem;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.red};
`;
