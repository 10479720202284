import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
    carouselDotsSelector,
    sciLanguageSelector,
} from 'src/components/shared/SciPage/SciPage.selector';
import { getLangCode } from 'src/utils/string';

export const useGoBack = () => {
    const carouselDotsState = useSelector(carouselDotsSelector);
    const lng = getLangCode(useSelector(sciLanguageSelector));
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        const currentPageIndex = carouselDotsState?.items.findIndex(
            (item) => item.pageEnum === carouselDotsState.currentPage,
        );
        if (
            carouselDotsState &&
            currentPageIndex !== undefined &&
            currentPageIndex > 0
        ) {
            navigate({
                pathname: carouselDotsState?.items[currentPageIndex - 1].path,
                search: createSearchParams(lng ? { lng } : {}).toString(),
            });
        }
    }, [carouselDotsState, lng, navigate]);

    return { goBack };
};
