import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { B2C } from 'cosmos-components';
import { SciContent } from 'src/components/shared/SciContent';
import { SciPage } from 'src/components/shared/SciPage';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import {
    setCarouselDotsCurrentPage,
    setCarouselPageIsCompletedAction,
} from 'src/components/shared/SciPage/SciPage.duck';
import { useSciContent } from 'src/hooks/useSciContent';
import { sciContentKeys } from 'src/constants';
import globalMsg from 'src/messages';

import * as S from './videos.style';
import { MODULE_PREFIX } from './videos.constants';
import { VideosForm } from './videos.form';
import msg from './videosMessages';

export const componentVideos = `${MODULE_PREFIX}-component`;

export const VideosComponent: FC = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        dispatch(setCarouselDotsCurrentPage(SciPagesEnum.Videos));
    }, [dispatch]);

    const { data: sciVideosPageLabel, isLoading: isSciVideosPageLoading } =
        useSciContent(sciContentKeys.sciVideosPage);

    return (
        <SciPage>
            <S.Container data-test-id={componentVideos}>
                <S.Header>
                    <S.Title>{intl.formatMessage(msg.pageItems.title)}</S.Title>
                    <S.StyleContainedModal
                        dialogInfo={
                            <SciContent
                                sciContentKey={sciContentKeys.sciInfoBoxVideos}
                            />
                        }
                        closeLabel={intl.formatMessage(globalMsg.buttons.close)}
                    />
                </S.Header>
                <S.Text>
                    <B2C.LoaderContainer
                        loading={isSciVideosPageLoading}
                        size={'small'}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: sciVideosPageLabel ?? '',
                            }}
                        ></span>
                    </B2C.LoaderContainer>
                </S.Text>
            </S.Container>
            <VideosForm
                successCallback={() => {
                    dispatch(
                        setCarouselPageIsCompletedAction({
                            page: SciPagesEnum.Videos,
                        }),
                    );
                }}
            />
        </SciPage>
    );
};
