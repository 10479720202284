import { defineMessages } from 'react-intl';

const pageItems = defineMessages({
    title: {
        id: 'videos.pageItems.title',
        defaultMessage: 'Videos',
    },
    numbering: {
        id: 'videos.pageItems.numbering',
        defaultMessage: '{current} OF {total}',
    },
});

const buttons = defineMessages({
    continue: {
        id: 'videos.buttons.continue',
        defaultMessage: 'Agree & Continue',
    },
});

const videosMessages = {
    pageItems,
    buttons,
};

export default videosMessages;
