import { useMutation } from '@tanstack/react-query';
import { SCIApi, selfCheckInRoute } from 'src/apis';
import { VideoContent } from 'src/types/SciContent';

export const useAcceptVideo = () => {
    const videoAcceptanceApiRoute = `${selfCheckInRoute}/contents/videos/accept`;
    const acceptVideo = async (video: VideoContent): Promise<void> => {
        const apiInstance = SCIApi.getInstance();
        await apiInstance.post(videoAcceptanceApiRoute, {
            key: video.key,
            title: video.title,
        });
    };

    const { mutate } = useMutation({
        mutationFn: acceptVideo,
    });

    return {
        mutate,
    };
};
