import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { getScreenBreaks } from 'src/utils/htmlHelper';

import msg from './sciPageMessages';

interface SessionExpiryTimerProps {
    expiryDate: Date;
}

const TimerDisplay = styled.div`
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => getScreenBreaks(props).screenMDMax}) {
        margin-top: 1.6rem;
        margin-bottom: 0;
    }
`;

const getFormattedTimeDiff = (expiryDate: Date): string => {
    if (!(expiryDate instanceof Date) || isNaN(expiryDate.getTime())) {
        return '00:00';
    }

    const now = new Date();
    const diff = expiryDate.getTime() - now.getTime();

    if (diff <= 0) {
        return '00:00';
    }

    const minutes = Math.floor(diff / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);

    return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
};

export const SessionExpiryTimer: FC<SessionExpiryTimerProps> = ({
    expiryDate,
}) => {
    const [timeLeft, setTimeLeft] = useState<string>(() =>
        getFormattedTimeDiff(expiryDate),
    );
    const intl = useIntl();

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(getFormattedTimeDiff(expiryDate));
        }, 1000);

        return () => clearInterval(timer);
    }, [expiryDate]);

    return (
        <TimerDisplay>
            <span>{intl.formatMessage(msg.content.expiryTimer)}</span>
            <span>{timeLeft}</span>
        </TimerDisplay>
    );
};
