import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import creditCardType from 'credit-card-type';

import { useBookingSummary } from 'src/hooks/useBookingSummary';
import { setCarouselPageIsCompletedAction } from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { CaBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { minimumPaymentAmount } from 'src/constants';

import { usePaymentSurcharge } from './usePaymentSurcharge';
import { usePaymentForm } from './usePaymentForm';
import { usePaymentIssuers } from './usePaymentIssuers';
import { useWindcave } from './useWindcave';
import { PaymentIssuerResponse, PaymentFormValues } from '../payment.type';
import { PaymentMethodEnum } from '../payment.enum';

export const usePayment = () => {
    const dispatch = useDispatch();
    const configuration = useConfiguration();
    const brandName = useSelector(sciBrandNameSelector);
    const isCa = brandName === CaBrandNames.canadream;
    const [paymentAmount, setPaymentAmount] = useState(0);
    const needToConsiderSurcharge =
        configuration.functionalities.payments.calculateSurcharge;

    const { data: bookingSummaryData, isLoading: isBookingSummaryLoading } =
        useBookingSummary();

    const originalAmount = bookingSummaryData?.bookingTotalPrice?.balance ?? 0;

    const { control, setValue, getValues, watch, handleSubmit } =
        usePaymentForm({ bookingSummaryData });

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
        getValues('paymentMethod'),
    );
    const [selectedPaymentIssuer, setSelectedPaymentIssuer] = useState(
        getValues('issuer'),
    );

    const onPaymentComplete = useCallback(
        (option?: { ignorePaymentMethod: boolean }) => {
            if (option?.ignorePaymentMethod) {
                dispatch(
                    setCarouselPageIsCompletedAction({
                        page: SciPagesEnum.Payment,
                    }),
                );
            } else {
                dispatch(
                    setCarouselPageIsCompletedAction({
                        page: SciPagesEnum.Payment,
                        urlParams: {
                            paymentMethod: selectedPaymentMethod,
                        },
                    }),
                );
            }
        },
        [dispatch, selectedPaymentMethod],
    );

    const {
        mutate: windcaveMutate,
        isLoading: isPaymentInProgress,
        error: paymentError,
    } = useWindcave(onPaymentComplete);

    const onPaymentIssuersSuccess = (data: PaymentIssuerResponse[]) => {
        if (data?.length > 0) {
            setValue('issuer', '');
        }
    };

    const {
        isLoading: paymentIssuerLoading,
        data: paymentIssuers,
        error: paymentIssuerError,
    } = usePaymentIssuers(onPaymentIssuersSuccess);

    const {
        mutate: mutatePaymentSurcharge,
        data: paymentSurcharge,
        isLoading: paymentSurchargeIsLoading,
        error: paymentSurchargeError,
    } = usePaymentSurcharge();

    const watchPaymentDescription = useCallback(
        (data: any, info: any) => {
            if (needToConsiderSurcharge) {
                if (
                    info?.name === 'paymentMethod' &&
                    data?.paymentMethod === PaymentMethodEnum.Manual
                ) {
                    setPaymentAmount(
                        bookingSummaryData?.bookingTotalPrice?.balance ?? 0,
                    );
                } else if (
                    info?.name === 'issuer' ||
                    info?.name === 'paymentMethod'
                ) {
                    const issuerCode = paymentIssuers?.find(
                        (issuer) => issuer.name === data.issuer,
                    )?.code;
                    const paymentMethod = data?.paymentMethod;

                    if (issuerCode && paymentMethod) {
                        mutatePaymentSurcharge({
                            method: paymentMethod,
                            issuer: issuerCode,
                        });
                    }
                }
            }

            if (data.paymentMethod) {
                setSelectedPaymentMethod(data.paymentMethod);
            }
            if (data.issuer) {
                setSelectedPaymentIssuer(data.issuer);
            }
        },
        [
            bookingSummaryData?.bookingTotalPrice?.balance,
            paymentIssuers,
            mutatePaymentSurcharge,
            needToConsiderSurcharge,
        ],
    );

    useEffect(() => {
        const subscription = watch((data, info) =>
            watchPaymentDescription(data, info),
        );
        return () => subscription.unsubscribe();
    }, [watch, watchPaymentDescription]);

    const getIssuerByCardNumber = (
        cardNumber: any,
        paymentIssuers: PaymentIssuerResponse[] = [],
    ) => {
        const cardTypeObj = creditCardType(cardNumber);
        const cardType = cardTypeObj?.[0]?.niceType?.toLowerCase();

        return (
            paymentIssuers.find(
                (issuer) => cardType === issuer.code.toLowerCase(),
            )?.code ?? ''
        );
    };

    const onSubmit = async (data: PaymentFormValues) => {
        if (originalAmount < minimumPaymentAmount && !isBookingSummaryLoading) {
            // If nothing to pay redirect to confirmation
            onPaymentComplete({ ignorePaymentMethod: true });
            return;
        }

        const issuerCode =
            paymentIssuers?.find((issuer) => issuer.name === data.issuer)
                ?.code ??
            getIssuerByCardNumber(data.cardNumber, paymentIssuers);

        const paymentSurchargePayload = {
            method: data?.paymentMethod,
            issuer: issuerCode,
        };

        windcaveMutate({
            cardDetails: data,
            paymentSurchargePayload,
        });
    };

    useEffect(() => {
        // Initially set it to bookingSummaryAmount
        setPaymentAmount(originalAmount);

        if (
            !isCa &&
            originalAmount < minimumPaymentAmount &&
            !isBookingSummaryLoading
        ) {
            // If nothing to pay redirect to confirmation
            onPaymentComplete({ ignorePaymentMethod: true });
        }
    }, [originalAmount, isBookingSummaryLoading, isCa, onPaymentComplete]);

    useEffect(() => {
        if (paymentSurcharge?.totalAmount) {
            setPaymentAmount(paymentSurcharge?.totalAmount);
        }
    }, [paymentSurcharge]);

    return {
        paymentAmount,
        paymentIssuers,
        paymentIssuerLoading,
        paymentSurcharge: paymentSurcharge?.amount,
        paymentSurchargeIsLoading,
        needToConsiderSurcharge,
        isPaymentInProgress,
        selectedPaymentMethod,
        selectedPaymentIssuer,
        currency: bookingSummaryData?.currency,
        paymentError,
        error: paymentIssuerError || paymentSurchargeError,
        control,
        onSubmit: handleSubmit(onSubmit),
        onSubmitManual: onPaymentComplete,
    };
};
