import styled, { css } from 'styled-components';
import {
    FormField,
    Icon,
    PrimaryButton,
    SecondaryButton,
} from 'cosmos-components';
import { ContainedModal } from 'src/components/shared/ContainedModal';
import { DeviceType } from 'src/enums';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export const StyledForm = styled.form`
    margin: auto;
    margin-top: 3.2rem;
`;

export const PaymentMethodContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    gap: 1.6rem;
    width: 100%;
    > * {
        display: flex;
        flex: 1;
    }
`;

export const PaymentCardContainer = styled.div``;

export const Header = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 1.1rem;
`;

export const HeaderText = styled.span`
    font-size: 1.4rem;
    line-height: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.03rem;
`;

export const SubLabel = styled.span`
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
`;

export const PaymentMethodCard = styled.div<{
    deviceType: DeviceType;
    selected: boolean;
    disabled?: boolean;
}>`
    display: flex;
    flex: 1;
    cursor: ${({ selected }) => (selected ? 'initial' : 'cursor')};
    align-items: center;

    height: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '8rem' : '14rem'};
    cursor: ${({ selected }) => (selected ? 'initial' : 'cursor')};

    border: 0.1rem solid ${({ theme: { colors } }) => colors.tertiaryGrey};
    border-radius: 0.5rem;
    background-color: ${({ theme: { colors }, selected }) =>
        selected ? colors.b2cRedThl : colors.white};

    &:hover {
        ${({ theme: { colors }, selected, disabled }) =>
            !selected &&
            !disabled &&
            css`
                background-color: ${colors?.b2cLightOrange};
                cursor: pointer;
                span {
                    color: ${colors.white};
                }
                svg {
                    fill: ${colors.white};
                }
            `}
    }
`;

export const PaymentMethodCardContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`;

export const PaymentMethodCardText = styled.span<{
    deviceType: DeviceType;
    selected: boolean;
}>`
    text-align: center;
    color: ${({ theme: { colors }, selected }) =>
        selected ? colors.white : colors.black};
    font-size: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '1.4rem' : '1.6rem'};
`;

export const PaymentMethodCardStyledIcon = styled(Icon)<{
    deviceType: DeviceType;
    selected: boolean;
}>`
    margin-bottom: 1rem;
    aspect-ratio: 1;
    height: auto;
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '2.4rem' : '5.2rem'};
    fill: ${({ theme: { colors }, selected }) =>
        selected ? colors.white : colors.black};
`;

export const StyledPaymentFormField = styled(FormField)`
    margin-top: 1.5rem;
    margin-bottom: 0;
    flex: 1 1 0;
    label {
        margin-bottom: 0.5rem;
    }
`;

export const ExpiryWrapper = styled.div`
    flex-basis: 50%;
`;

export const ActionWrapper = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        flex-direction: column;
    }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin: 1.6rem 0;
    width: 49%;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 1;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
    position: relative;
    color: ${({ theme: { colors } }) => colors.secondaryOrange};
    border: 1px solid ${({ theme: { colors } }) => colors.secondaryOrange};
    margin: 1.6rem 0;
    width: 49%;

    &:hover,
    &:focus,
    &:active {
        color: ${({ theme: { colors } }) => colors.secondaryOrange};
        border-color: ${({ theme: { colors } }) => colors.secondaryOrange};
        background-color: transparent;
    }

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 2;
        margin: 0;
    }
`;

export const StyleContainedModal = styled(ContainedModal)`
    & > button {
        padding-bottom: 0;
    }
    .rc-dialog {
        width: 100% !important;

        .rc-dialog-body {
            font-size: 1.4rem;

            a {
                color: ${({ theme: { colors } }) => colors.secondaryOrange};
            }
        }

        .rc-dialog-content {
            min-height: 10rem !important;
        }
    }
`;
