import { useIntl } from 'react-intl';
import { CosmosEnums } from 'cosmos-components';

import { currencySymbols, minimumPaymentAmount } from 'src/constants';

import msg from './payment.messages';
import * as S from './payment.style';

export const DueToday = ({
    paymentAmount,
    currency,
}: {
    paymentAmount: number;
    currency?: CosmosEnums.Currencies;
}) => {
    const intl = useIntl();
    const finalBalanceDueMessage = intl.formatMessage(
        msg.paymentBox.finalBalanceDue,
    );

    const currencySymbol =
        currencySymbols[currency ?? CosmosEnums.Currencies.NZD];

    return (
        <S.DueTodayContainer>
            <S.DueTodayPaymentSection>
                <S.PaymentText bold>{finalBalanceDueMessage}:</S.PaymentText>
                <S.PaymentText>
                    {currency ? currency : ''}
                    {currencySymbol}
                    {(paymentAmount >= minimumPaymentAmount
                        ? paymentAmount
                        : 0
                    )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                </S.PaymentText>
            </S.DueTodayPaymentSection>
        </S.DueTodayContainer>
    );
};
