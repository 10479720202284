import { VideoContent } from 'src/types/SciContent';
import { sciVideoContentKeys } from '../../constants';

type MockVideoResType = {
    [key in (typeof sciVideoContentKeys)[keyof typeof sciVideoContentKeys]]: VideoContent[];
};

export const MOCK_VIDEO_RES: MockVideoResType = {
    [sciVideoContentKeys.sciVideosVideo]: [
        {
            key: 'sciVideosVideo',
            order: 1,
            title: 'MHA Demo Video',
            url: 'https://www.youtube.com/embed/AziHS-DUYt4',
        },
        {
            key: 'sciVideosVideo',
            order: 2,
            title: 'MHA Winter Video',
            url: 'https://www.youtube.com/embed/0wA3jiMrAWg',
        },
        {
            key: 'sciVideosideo',
            order: 3,
            title: 'Canadream Safety Video',
            url: 'https://www.youtube.com/embed/j2LT0CTkHlc',
        },
    ],
};
