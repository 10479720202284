import * as yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { B2CInterface } from 'cosmos-components';

import { useConfiguration } from 'src/hooks/useConfiguration';
import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { minimumPaymentAmount } from 'src/constants';

import { PaymentFormValues } from '../payment.type';
import { PaymentMethodEnum } from '../payment.enum';
import msg from '../payment.messages';

interface UsePaymentFormProps {
    bookingSummaryData?: B2CInterface.B2CBookingSummaryInterface;
}

export const usePaymentForm = ({
    bookingSummaryData,
}: UsePaymentFormProps = {}) => {
    const intl = useIntl();
    const configuration = useConfiguration();
    const langState = useSelector(sciLanguageSelector);
    const balance = bookingSummaryData?.bookingTotalPrice?.balance ?? 0;

    const paymentMethodLabel = intl.formatMessage(msg.label.paymentMethod);
    const safetyVideoError = intl.formatMessage(
        msg.validationErrors.safetyVideo,
    );
    const issuerError = intl.formatMessage(
        msg.validationErrors.cardTypeRequired,
    );
    const cardNumberError = intl.formatMessage(
        msg.validationErrors.cardNumberRequired,
    );
    const cardHolderNameError = intl.formatMessage(
        msg.validationErrors.cardHolderNameRequired,
    );
    const cardExpiryDateError = intl.formatMessage(
        msg.validationErrors.cardExpiryDateRequired,
    );
    const cardCVVError = intl.formatMessage(
        msg.validationErrors.securityCodeRequired,
    );
    const shouldHaveDigits = intl.formatMessage(
        msg.validationErrors.shouldHaveDigits,
    );
    const termsAndConditionsNeedToBeSelected = intl.formatMessage(
        msg.validationErrors.termsAndConditionsNeedToBeSelected,
    );

    const baseShapeSchema = {
        paymentMethod: yup.string().required().label(paymentMethodLabel),
        issuer: configuration.functionalities.payments.showCardType
            ? yup.string().required(issuerError)
            : yup.string().notRequired(),
        cardNumber:
            balance >= minimumPaymentAmount
                ? yup.string().required().label(cardNumberError)
                : yup.string().notRequired(),
        cardHolderName:
            balance >= minimumPaymentAmount
                ? yup.string().required().label(cardHolderNameError)
                : yup.string().notRequired(),
        cardExpiryDate:
            balance >= minimumPaymentAmount
                ? yup.string().required().label(cardExpiryDateError)
                : yup.string().notRequired(),
        cardCVV:
            balance >= minimumPaymentAmount
                ? yup
                      .string()
                      .required(cardCVVError)
                      .matches(/^\d+$/, shouldHaveDigits)
                      .min(3)
                      .max(4)
                : yup.string().notRequired(),
        tc: yup.bool().oneOf([true], termsAndConditionsNeedToBeSelected),
        sciSafetyVideosAcceptance: configuration.functionalities
            .sciSafetyVideosAcceptance
            ? yup.bool().oneOf([true], safetyVideoError)
            : yup.bool().notRequired(),
    };

    const schema = yup.object().shape(baseShapeSchema);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState,
        trigger,
    } = useForm<PaymentFormValues>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            paymentMethod: PaymentMethodEnum.CreditCard,
            issuer: '',
            cardCVV: '',
            cardExpiryDate: '',
            cardHolderName: '',
            cardNumber: '',
            tc: false,
            sciSafetyVideosAcceptance: false,
        },
    });

    useEffect(() => {
        if (formState.isDirty) {
            trigger();
        }
    }, [langState]);

    return {
        control,
        watch,
        setValue,
        getValues,
        handleSubmit,
        formErrors: formState.errors,
    };
};
