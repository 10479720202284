import { FC } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';

import { useWindowDimensions } from 'src/hooks/useWindowDimensions';

import * as S from './CarousalDots.style';
import { SciPagesEnum } from '../SciPage/types';

export interface DotItem {
    label: string;
    isCompleted: boolean;
    path: string;
    pageEnum: SciPagesEnum;
}

export interface CarousalDotsProps {
    currentPageEnum: SciPagesEnum;
    items: DotItem[];
    lng?: string;
    className?: string;
}

export const CarousalDots: FC<CarousalDotsProps> = ({
    currentPageEnum,
    items,
    lng,
    className,
}) => {
    const navigate = useNavigate();
    useWindowDimensions();
    const currentPageIndex = items.findIndex(
        (item) => item.pageEnum === currentPageEnum,
    );

    const handleDotClick = (dot: DotItem) => {
        if (dot.isCompleted) {
            navigate({
                pathname: dot.path,
                search: createSearchParams(lng ? { lng } : {}).toString(),
            });
        }
    };

    return (
        <S.Wrapper data-test-id="carousel-dots" className={className}>
            <S.DotsWrapper>
                {items?.map((item, index) => (
                    <S.ClickableDiv
                        active={item.isCompleted}
                        key={`Dot-${item.label}`}
                    >
                        <S.Dot
                            isCompleted={item.isCompleted}
                            active={index === currentPageIndex}
                            role="dot"
                            onClick={() => handleDotClick(item)}
                        >
                            {item.label}
                        </S.Dot>
                    </S.ClickableDiv>
                ))}
            </S.DotsWrapper>
        </S.Wrapper>
    );
};
