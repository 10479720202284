import { rest } from 'msw';
import { VideoContent } from 'src/types/SciContent';
import { sciVideoContentKeys } from 'src/constants';

import { getApiBaseUrl } from '../../utils/configStore';
import { getVideoContentRoute } from '../useSciVideoContent';
import { MOCK_VIDEO_RES } from './useSciVideoContent.mock.response';

const baseURL = getApiBaseUrl();
const delayDuration = 1000;

const mock = [
    rest.get<VideoContent[]>(
        `${baseURL}${getVideoContentRoute}`,
        (req, res, ctx) => {
            const key = req.url.searchParams.get(
                'key',
            ) as (typeof sciVideoContentKeys)[keyof typeof sciVideoContentKeys];

            if (!key || !MOCK_VIDEO_RES[key]) {
                return res(ctx.delay(delayDuration), ctx.status(500));
            }

            return res(
                ctx.delay(delayDuration),
                ctx.status(200),
                ctx.json(MOCK_VIDEO_RES[key]),
            );
        },
    ),
];

export default mock;
